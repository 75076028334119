import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import PageContainer from '@components/PageContainer';
import type { HomepageDocument } from '@raise/sanity';

import Content from './Content';
import Tagline from './Tagline';
import Tron from './Tron';

const Hero: React.FC<HomepageDocument['hero']> = ({
  tagline,
  description,
  content,
}) => (
  <Box
    bg="gray.900"
    bgGradient="linear(to-b, gray.800, black)"
    color="white"
    pt={20}
    overflow="hidden"
    position="relative"
  >
    <Tron />
    <PageContainer pt={[8, null, 16, null, 24]} position="relative">
      <Tagline {...tagline} />
      <Text
        mt={[8, null, 12]}
        mb={[12, null, 24]}
        fontSize={['xl', null, '2xl']}
        lineHeight="tall"
        maxW="4xl"
        textAlign="center"
        mx="auto"
      >
        {description}
      </Text>
      <Content content={content} />
    </PageContainer>
  </Box>
);

export default Hero;
