import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Image,
  keyframes,
  Link,
  Show,
  Text,
  useBreakpoint,
} from '@chakra-ui/react';
import { PortableText } from '@portabletext/react';
import NextLink from 'next/link';

import Icon from '@components/Icon';
import Underline from '@components/Underline';
import { sanityImage } from '@lib/integrations/sanity';
import type { HomepageDocument } from '@raise/sanity';

const ProgressBar = ({
  speed,
  color,
  isActive,
  isPaused,
}: {
  speed: number;
  color: string;
  isActive: boolean;
  isPaused: boolean;
}) => {
  const keyframeAnimation = keyframes`
    0% { width: 0%; }
    100% { width: 100%; }
  `;

  const animation = `${keyframeAnimation} ${speed}ms linear`;

  return (
    <Box
      h={3}
      position="relative"
      w="full"
      mt={8}
      bg="gray.700"
      borderRadius="sm"
      overflow="hidden"
    >
      {isActive && (
        <Box
          h="full"
          bg={color}
          animation={animation}
          style={{
            animationPlayState: isPaused ? 'paused' : 'running',
          }}
        />
      )}
    </Box>
  );
};

const ContentTab: React.FC<{
  title: string;
  logo: HomepageDocument['hero']['content'][number]['logo'];
  onClick?: () => void;
  isActive: boolean;
  isPaused: boolean;
  speed: number;
  color: HomepageDocument['hero']['content'][number]['color'];
}> = ({ title, logo, onClick, isActive, isPaused, speed, color }) => (
  <Box key={title} flex={1} cursor="pointer" onClick={onClick}>
    <Image
      src={sanityImage(logo).url()}
      alt={title}
      height={14}
      mx="auto"
      filter={isActive ? 'none' : 'grayscale(100%)'}
      opacity={isActive ? 1 : 0.25}
    />
    <ProgressBar
      speed={speed}
      color={color.value}
      isActive={isActive}
      isPaused={isPaused}
    />
  </Box>
);

const Content: React.FC<{
  content: HomepageDocument['hero']['content'];
}> = ({ content }) => {
  // The speed of the slider
  const SPEED = 8000;

  // State to keep track of the current slide and various calculations
  const [index, setIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [startedAt, setStartedAt] = useState(Date.now());
  const [remainingTime, setRemainingTime] = useState(SPEED);

  // Ref to the timer
  const timerRef = useRef<NodeJS.Timeout>();

  // Effect to handle the autoplay
  useEffect(() => {
    // Start the autoplay timer
    timerRef.current = setInterval(() => {
      if (!isPaused) {
        setIndex((index + 1) % content.length);
        setStartedAt(Date.now());
        setRemainingTime(SPEED);
      }
    }, remainingTime);

    // Clean up timer on unmount
    return () => {
      clearInterval(timerRef.current);
    };
  }, [index, isPaused, content.length, remainingTime]);

  const breakpoint = useBreakpoint();

  // Pause on mouse enter
  const pause = useCallback(() => {
    if (breakpoint === 'base' || breakpoint === 'sm') return;

    setIsPaused(true);
    clearInterval(timerRef.current);
    setRemainingTime(SPEED - (Date.now() - startedAt));
  }, [breakpoint, startedAt]);

  // Resume on mouse leave
  const resume = useCallback(() => {
    if (breakpoint === 'base' || breakpoint === 'sm') return;

    setIsPaused(false);
    timerRef.current = setInterval(() => {
      if (!isPaused) {
        setIndex((index + 1) % content.length);
        setStartedAt(Date.now());
        setRemainingTime(SPEED);
      }
    }, remainingTime);
  }, [breakpoint, content.length, index, isPaused, remainingTime]);

  // Go to a specific slide
  const goTo = (index: number) => {
    setIndex(index);
    setStartedAt(Date.now());
    setRemainingTime(SPEED);
  };

  // Breathing colored blur keyframe
  const keyframeAnimation = keyframes`
    0% { opacity: 0.75; }
    50% { opacity: 1 }
    100% { opacity: 0.75; }
  `;

  return (
    <Box onMouseEnter={pause} onMouseLeave={resume}>
      <Show below="md">
        <ContentTab
          key={content[index].title}
          title={content[index].title}
          logo={content[index].logo}
          color={content[index].color}
          isActive
          isPaused={isPaused}
          speed={SPEED}
        />
      </Show>
      <Show above="md">
        <Flex gap={8}>
          {content.map((item, i) => (
            <ContentTab
              key={item.title}
              title={item.title}
              logo={item.logo}
              color={item.color}
              onClick={() => goTo(i)}
              isActive={index === i}
              isPaused={isPaused}
              speed={SPEED}
            />
          ))}
        </Flex>
      </Show>
      <Box position="relative" h={[80, 72, 80]}>
        {content.map((item, i) => (
          <Box
            key={item.title}
            position="absolute"
            top={0}
            w="full"
            h="full"
            opacity={index === i ? 1 : 0}
            transition="opacity 0.5s ease-in-out"
          >
            <Box
              position="absolute"
              bottom="-50%"
              w="full"
              h={48}
              bg={content[i].color.value}
              filter="blur(200px)"
              animation={`${keyframeAnimation} ${
                SPEED / 2
              }ms ease-in-out infinite`}
            />
            <Box
              position="relative"
              zIndex={1}
              fontSize={['xl', null, '2xl']}
              lineHeight="tall"
              maxW="4xl"
              mt={[6, null, 12]}
              textAlign="center"
              mx="auto"
              color="whiteAlpha.800"
            >
              <PortableText
                value={content[i].content}
                components={{
                  block: {
                    normal: ({ children }) => <Text>{children}</Text>,
                  },
                  marks: {
                    strong: ({ children }) => (
                      <Text as="b" fontWeight="semibold">
                        {children}
                      </Text>
                    ),
                    em: ({ children }) => (
                      <Text as="i" fontStyle="italic">
                        {children}
                      </Text>
                    ),
                    underline: ({ children }) => (
                      <Text as="span" textDecoration="underline">
                        {children}
                      </Text>
                    ),
                    highlight: ({ text, value }) => (
                      <Underline height="3px" color={value.value}>
                        {text}
                      </Underline>
                    ),
                  },
                }}
              />
              <Link
                as={NextLink}
                href={content[i].link.url}
                colorScheme={content[i].color.value.split('.')[0]}
                mt={4}
                fontWeight="semibold"
              >
                {content[i].link.text}
                <Icon style="solid" icon="arrow-right" ml={3} />
              </Link>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Content;
