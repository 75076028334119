import React from 'react';
import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import { PortableText } from '@portabletext/react';
import NextLink from 'next/link';

import { SanityIcon } from '@components/Icon';
import PageContainer from '@components/PageContainer';
import defaultComponents from '@components/PortableText';
import { sanityImage } from '@lib/integrations/sanity';
import type { HomepageDocument } from '@raise/sanity';

import CtaItem from './CTAItem';

const About: React.FC<HomepageDocument['about']> = ({
  top_content,
  reasons,
  bottom_content,
  ctas,
  partners,
}) => {
  return (
    <Box bg="brand.100">
      <PageContainer py={[16, null, 24]}>
        <Box lineHeight="tall" fontSize={['lg', null, 'xl']}>
          <PortableText
            value={top_content}
            components={defaultComponents({
              block: {
                h2: () => ({
                  size: '2xl',
                  lineHeight: 'normal',
                  mt: 0,
                }),
              },
            })}
          />
          <Flex
            my={12}
            direction={['column', null, 'row']}
            justifyContent="space-evenly"
            alignItems="flex-start"
            gap={8}
          >
            {reasons.map((reason) => (
              <Box key={reason.title} flex={1}>
                <Flex alignItems="center" mb={4}>
                  <SanityIcon
                    {...reason.icon}
                    color="brand.500"
                    fontSize="3xl"
                    mr={4}
                  />
                  <Text fontSize="lg" fontWeight="semibold">
                    {reason.title}
                  </Text>
                </Flex>
                <Text fontSize="lg" color="blackAlpha.700">
                  {reason.description}
                </Text>
              </Box>
            ))}
          </Flex>
          <PortableText
            value={bottom_content}
            components={defaultComponents({
              block: {
                h2: () => ({
                  size: '2xl',
                  lineHeight: 'normal',
                }),
              },
            })}
          />
        </Box>
        <Flex
          direction={['column', null, 'row']}
          mt={8}
          alignItems="center"
          gap={6}
        >
          {ctas.map((cta, i) => (
            <CtaItem
              key={cta.text}
              cta={{ ...cta, fn: 'link' }}
              colorScheme={{ value: 'brand' }}
              isButton={i === 0}
            />
          ))}
        </Flex>
        <Box mt={12}>
          <Text
            textTransform="uppercase"
            fontWeight="bold"
            letterSpacing="widest"
            color="blackAlpha.500"
          >
            {partners.title}
          </Text>
          <Flex
            mt={8}
            direction={['column', null, 'row']}
            alignItems="center"
            gap={12}
          >
            {partners.partners.map((partner) => {
              const imageProps = {
                src: sanityImage(partner.logo).url(),
                alt: partner.name,
                maxH: 8,
                transition: 'filter 0.2s ease-in-out',
                filter: 'grayscale(100%)',
                _hover: {
                  filter: 'none',
                },
              };

              if (partner.link) {
                return (
                  <NextLink
                    key={partner.name}
                    href={partner.link}
                    target="_blank"
                  >
                    <Tooltip label={partner.tooltip} hasArrow offset={[0, 24]}>
                      <Image {...imageProps} alt={partner.name} />
                    </Tooltip>
                  </NextLink>
                );
              }

              return (
                <Tooltip label={partner.tooltip} key={partner.name} hasArrow>
                  <Image {...imageProps} alt={partner.name} />
                </Tooltip>
              );
            })}
          </Flex>
        </Box>
      </PageContainer>
    </Box>
  );
};

export default About;
