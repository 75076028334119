import {
  AspectRatio,
  type AspectRatioProps,
  Box,
  type BoxProps,
  Button,
  type ButtonProps,
  Code,
  type CodeProps,
  Heading,
  type HeadingProps,
  Image,
  type ImageProps,
  Link,
  type LinkProps,
  ListItem,
  type ListItemProps,
  type ListProps,
  OrderedList,
  Text,
  type TextProps,
  UnorderedList,
} from '@chakra-ui/react';
import type {
  PortableTextComponentProps,
  PortableTextComponents,
  PortableTextMarkComponentProps,
  PortableTextTypeComponentProps,
} from '@portabletext/react';
import NextLink from 'next/link';

import Underline, { type UnderlineProps } from '@components/Underline';
import { sanityImage } from '@lib/integrations/sanity';

type Props = {
  colorScheme?: string;
  color?: string;
  types?: {
    image?: (props: PortableTextTypeComponentProps<any>) => ImageProps;
    youtube?: (props: PortableTextTypeComponentProps<any>) => AspectRatioProps;
  };
  block?: {
    normal?: (props: PortableTextComponentProps<any>) => TextProps | BoxProps;
    h1?: (props: PortableTextComponentProps<any>) => HeadingProps;
    h2?: (props: PortableTextComponentProps<any>) => HeadingProps;
    h3?: (props: PortableTextComponentProps<any>) => HeadingProps;
    h4?: (props: PortableTextComponentProps<any>) => HeadingProps;
    h5?: (props: PortableTextComponentProps<any>) => HeadingProps;
    h6?: (props: PortableTextComponentProps<any>) => HeadingProps;
    blockquote?: (props: PortableTextComponentProps<any>) => BoxProps;
  };
  marks?: {
    strong?: (props: PortableTextMarkComponentProps<any>) => TextProps;
    em?: (props: PortableTextMarkComponentProps<any>) => TextProps;
    underline?: (props: PortableTextMarkComponentProps<any>) => TextProps;
    link?: (props: PortableTextMarkComponentProps<any>) => LinkProps;
    highlight?: (props: PortableTextMarkComponentProps<any>) => UnderlineProps;
    button?: (props: PortableTextMarkComponentProps<any>) => ButtonProps;
    code?: (props: PortableTextMarkComponentProps<any>) => CodeProps;
  };
  list?: {
    number?: (props: PortableTextComponentProps<any>) => ListProps;
    bullet?: (props: PortableTextComponentProps<any>) => ListProps;
  };
  listItem?: {
    number?: (props: PortableTextComponentProps<any>) => ListItemProps;
    bullet?: (props: PortableTextComponentProps<any>) => ListItemProps;
  };
};

const defaultComponents = ({
  colorScheme = 'brand',
  color = 'brand.500',
  types: { image, youtube } = {},
  block: { normal, h1, h2, h3, h4, h5, h6, blockquote } = {},
  marks: { strong, em, underline, link, highlight, button, code } = {},
  list: { number, bullet } = {},
  listItem: { number: numberItem, bullet: bulletItem } = {},
}: Props): PortableTextComponents => ({
  types: {
    image: (props) => {
      const { value } = props;

      return (
        <Image
          src={sanityImage(value).url()}
          maxW="3xl"
          w="full"
          h="auto"
          mx="auto"
          my={[4, null, 8]}
          alt={value?.asset._ref}
          {...(image ? image(props) : {})}
        />
      );
    },
    youtube: (props) => {
      const {
        value: { id },
      } = props;

      return (
        <AspectRatio
          ratio={16 / 9}
          maxW="3xl"
          mx="auto"
          my={[4, null, 8]}
          {...(youtube ? youtube(props) : {})}
        >
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${id}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </AspectRatio>
      );
    },
  },
  block: {
    normal: (props) => {
      const { children } = props;

      if (
        Array.isArray(children) &&
        children.every((child) => typeof child === 'string')
      ) {
        return (
          <Text mb={4} {...(normal ? normal(props) : {})}>
            {children}
          </Text>
        );
      }

      return (
        <Box mb={4} {...(normal ? normal(props) : {})}>
          {children}
        </Box>
      );
    },
    h1: (props) => {
      const { children } = props;

      return (
        <Heading as="h1" size="2xl" mt={8} mb={4} {...(h1 ? h1(props) : {})}>
          {children}
        </Heading>
      );
    },
    h2: (props) => {
      const { children } = props;

      return (
        <Heading as="h2" size="xl" mt={8} mb={4} {...(h2 ? h2(props) : {})}>
          {children}
        </Heading>
      );
    },
    h3: (props) => {
      const { children } = props;

      return (
        <Heading as="h3" size="lg" mt={8} mb={4} {...(h3 ? h3(props) : {})}>
          {children}
        </Heading>
      );
    },
    h4: (props) => {
      const { children } = props;

      return (
        <Heading as="h4" size="md" mt={6} mb={4} {...(h4 ? h4(props) : {})}>
          {children}
        </Heading>
      );
    },
    h5: (props) => {
      const { children } = props;

      return (
        <Heading as="h5" size="sm" mt={6} mb={4} {...(h5 ? h5(props) : {})}>
          {children}
        </Heading>
      );
    },
    h6: (props) => {
      const { children } = props;

      return (
        <Heading as="h6" size="xs" mt={6} mb={4} {...(h6 ? h6(props) : {})}>
          {children}
        </Heading>
      );
    },
    blockquote: (props) => {
      const { children } = props;

      return (
        <Box
          as="blockquote"
          borderLeft="4px solid"
          borderColor="gray.300"
          pl={4}
          ml={0}
          my={[4, null, 8]}
          fontStyle="italic"
          {...(blockquote ? blockquote(props) : {})}
        >
          {children}
        </Box>
      );
    },
  },
  marks: {
    strong: (props) => {
      const { children } = props;

      return (
        <Text as="b" fontWeight="semibold" {...(strong ? strong(props) : {})}>
          {children}
        </Text>
      );
    },
    em: (props) => {
      const { children } = props;

      return (
        <Text as="i" fontStyle="italic" {...(em ? em(props) : {})}>
          {children}
        </Text>
      );
    },
    underline: (props) => {
      const { children } = props;

      return (
        <Text
          as="span"
          textDecoration="underline"
          {...(underline ? underline(props) : {})}
        >
          {children}
        </Text>
      );
    },
    link: (props) => {
      const { value, children } = props;
      const url = value?.href || '';

      return (
        <Link
          as={NextLink}
          href={url}
          target={url.startsWith('http') ? '_blank' : undefined}
          rel={url.startsWith('http') ? 'noreferrer' : undefined}
          colorScheme={colorScheme}
          variant="colored"
          {...(link ? link(props) : {})}
        >
          {children}
        </Link>
      );
    },
    highlight: (props) => {
      const { text } = props;

      return (
        <Underline
          height="5px"
          color={color}
          {...(highlight ? highlight(props) : {})}
        >
          {text}
        </Underline>
      );
    },
    button: (props) => {
      const {
        value: { link },
        text,
      } = props;

      return (
        <Button
          as={NextLink}
          href={link}
          size="lg"
          target={link.startsWith('http') ? '_blank' : undefined}
          rel={link.startsWith('http') ? 'noreferrer' : undefined}
          colorScheme={colorScheme}
          {...(button ? button(props) : {})}
        >
          {text}
        </Button>
      );
    },
    code: (props) => {
      const { children } = props;

      return (
        <Code
          colorScheme="gray"
          px={2}
          borderRadius="sm"
          {...(code ? code(props) : {})}
        >
          {children}
        </Code>
      );
    },
  },
  list: {
    bullet: (props) => {
      const { children } = props;

      return (
        <UnorderedList
          mt={4}
          marginInlineStart={8}
          {...(bullet ? bullet(props) : {})}
        >
          {children}
        </UnorderedList>
      );
    },
    number: (props) => {
      const { children } = props;

      return (
        <OrderedList
          mt={4}
          marginInlineStart={8}
          {...(number ? number(props) : {})}
        >
          {children}
        </OrderedList>
      );
    },
  },
  listItem: {
    bullet: (props) => {
      const { children } = props;

      return (
        <ListItem mb={2} {...(bulletItem ? bulletItem(props) : {})}>
          {children}
        </ListItem>
      );
    },
    number: (props) => {
      const { children } = props;

      return (
        <ListItem mb={2} {...(numberItem ? numberItem(props) : {})}>
          {children}
        </ListItem>
      );
    },
  },
  // TODO: Eventually maybe we support code_block?
});

export default defaultComponents;
