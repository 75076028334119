import { Box, keyframes } from '@chakra-ui/react';

const Tron = () => {
  const size = 50;

  const keyframeAnimation = keyframes`
    from { background-position: 0px -${size}px,0px 0px; }
    to { background-position: 0px ${size}px, 0px 0px; }
  `;

  const line = {
    color: 'rgba(255, 255, 255, 0.2)',
    thickness: '1px',
  };

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      w="full"
      h="full"
      style={{
        perspective: '400px',
        perspectiveOrigin: '50% 50%',
      }}
    >
      <Box
        width="200%"
        height="150%"
        position="absolute"
        left="-50%"
        bottom="-40%"
        backgroundImage={`-webkit-linear-gradient(${line.color} ${line.thickness}, transparent ${line.thickness}), -webkit-linear-gradient(left, ${line.color} ${line.thickness}, transparent ${line.thickness})`}
        backgroundSize={`${size}px ${size}px, ${size * 2}px ${size * 2}px`}
        transform="rotateX(75deg)"
        animation={`${keyframeAnimation} 4s infinite linear`}
      />
      <Box
        w="150%"
        h={48}
        mt={-24}
        ml="-25%"
        position="absolute"
        top="50%"
        bgGradient="linear(to-b, gray.900, black)"
        filter="blur(50px)"
      />
    </Box>
  );
};

export default Tron;
