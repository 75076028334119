import type { GetStaticProps, NextPage } from 'next';

import { MainLayout } from '@components/Layout';
import Components from '@components/pages/index';
import client, {
  coalesce,
  getSanityShared,
  type SanityShared,
} from '@lib/integrations/sanity';
import type { HomepageDocument } from '@raise/sanity';

type Props = {
  shared: SanityShared;
  page: HomepageDocument;
};

const Homepage: NextPage<Props> = ({ shared, page }) => {
  return (
    <MainLayout shared={shared} meta={page.meta}>
      <Components {...page} />
    </MainLayout>
  );
};

// @ts-ignore Sanity-typed can't generate types like this
export const getStaticProps: GetStaticProps<Props> = async (props) => ({
  props: {
    shared: await getSanityShared(props),
    // @ts-ignore Sanity-typed can't generate types like this
    page: await client.fetch(coalesce('homepage'), props),
  },
});

export default Homepage;
