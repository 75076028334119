import React from 'react';

import type { HomepageDocument } from '@raise/sanity';

import About from './About';
import Hero from './Hero';
import Product from './Product';

const Homepage: React.FC<HomepageDocument> = ({
  hero,
  investment,
  savings,
  education,
  about,
}) => {
  return (
    <>
      <Hero {...hero} />
      <Product {...investment} type="investment" />
      <Product {...savings} type="savings" />
      <Product {...education} type="education" />
      <About {...about} />
    </>
  );
};

export default Homepage;
