import type { ReactPortal, RefObject } from 'react';
import { createPortal } from 'react-dom';
import { Box, type BoxProps, Flex, Text } from '@chakra-ui/react';
import type { CustomLayer, CustomLayerProps, Point } from '@nivo/line';
import dynamic from 'next/dynamic';

import { formatUSD } from '@raise/common';

import { getLast } from './_helpers';

const DotsItem = dynamic(() => import('@nivo/core').then((m) => m.DotsItem), {
  ssr: false,
});

export const GraphCard: React.FC<BoxProps> = (props) => (
  <Box
    position="absolute"
    m={4}
    px={4}
    py={3}
    bg="white"
    borderRadius="md"
    boxShadow="md"
    {...props}
  />
);

export type SpecialLayerProps = CustomLayerProps & {
  currentSlice: {
    points: Point[];
  };
};

export type SpecialLayer = (props: SpecialLayerProps) => React.ReactNode;

type LegendProps = SpecialLayerProps & {
  after: string;
  year: string;
  years: string;
  net: string;
  margins: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  innerRef: RefObject<HTMLDivElement>;
};

type Datum = Point['data'] & { name: string; color: string };

export const Legend = ({
  currentSlice,
  points,
  after,
  year,
  years,
  net,
  margins,
  innerRef,
}: LegendProps): ReactPortal | null => {
  if (!innerRef.current) return null;

  const lines = (currentSlice ? currentSlice.points : points)
    .reduce((prev, curr) => {
      const serieId = curr.serieId.toString();

      if (!prev.includes(serieId)) {
        prev.push(serieId);
      }

      return prev;
    }, [] as string[])
    .sort((a, b) => {
      // Put "raise" first
      if (a === 'raise') return -1;
      if (b === 'raise') return 1;

      // Otherwise, sort alphabetically
      if (a < b) return -1;
      if (a > b) return 1;

      return 0;
    });

  const data = lines.map((line) => {
    const last = getLast(currentSlice ? currentSlice.points : points, line);

    return {
      color: last.serieColor,
      ...last.data,
    };
  }) as unknown as Datum[];

  return createPortal(
    <GraphCard left={`${margins.left}px`} top={`${margins.top}px`}>
      <Flex direction="column">
        <Text color="gray.900" fontWeight="bold" fontSize="lg">
          {after} {+data[0].x + 1} {+data[0].x + 1 === 1 ? year : years}, {net}
          ...
        </Text>
        {data.map((d) => (
          <Flex
            key={d.name}
            mt={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              color={d.color}
              fontWeight="bold"
              fontSize="lg"
              whiteSpace="nowrap"
              mr={8}
            >
              {d.name}
            </Text>
            <Text
              color="gray.800"
              fontFamily="mono"
              fontSize="2xl"
              data-testid={`graph-legend-${d.name
                .toLowerCase()
                .split(' ')
                .join('-')}`}
            >
              {formatUSD(+d.y, 0)}
            </Text>
          </Flex>
        ))}
      </Flex>
    </GraphCard>,
    innerRef.current,
  );
};

const POINT_SIZE = 14;

export const FinalPoints: CustomLayer = ({ points, ...props }) => {
  const lines = points.reduce((prev, curr) => {
    const serieId = curr.serieId.toString();

    if (!prev.includes(serieId)) {
      prev.push(serieId);
    }

    return prev;
  }, [] as string[]);

  const data = lines.map((line) => getLast(points, line));

  return (
    <g>
      {data.map((d) => (
        <DotsItem
          key={d.id}
          x={d.x}
          y={d.y}
          datum={d.data}
          size={POINT_SIZE}
          color={d.color}
          borderWidth={props.pointBorderWidth || 0}
          borderColor={d.borderColor}
        />
      ))}
    </g>
  );
};

export const Points: SpecialLayer = ({ currentSlice }) => {
  if (!currentSlice) return null;

  return (
    <g>
      {currentSlice.points.map((point: any) => (
        <circle
          key={point.id}
          r={POINT_SIZE / 2}
          cx={point.x}
          cy={point.y}
          fill={point.color}
          strokeWidth={0}
        />
      ))}
    </g>
  );
};

export const MobileLineDataDisplay: React.FC<{
  after: string;
  years: string;
  net: string;
  colorScheme: {
    value: string;
  };
  data: {
    x: number;
    y: number;
    name: string;
  }[];
}> = ({ after, years, net, colorScheme, data }) => (
  <Box fontSize="lg">
    <Text textAlign="center" fontWeight="semibold">
      {after} {+data[0].x + 1} {years}, {net}...
    </Text>
    {data.map((d, i) => (
      <Flex
        key={d.name}
        mt={4}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text
          fontWeight="semibold"
          color={`${i === 0 ? colorScheme.value : 'gray'}.500`}
        >
          {d.name}
        </Text>
        <Text fontSize="2xl" fontFamily="mono">
          {formatUSD(+d.y, 0)}
        </Text>
      </Flex>
    ))}
  </Box>
);
