import React from 'react';
import { Box, Show, Text, useTheme } from '@chakra-ui/react';
import type { CustomLayer } from '@nivo/line';
import dynamic from 'next/dynamic';

import { abbreviateUSD, capitalize, getProjections } from '@raise/common';
import type { HomepageDocument } from '@raise/sanity';

import {
  FinalPoints,
  Legend,
  MobileLineDataDisplay,
  Points,
  type SpecialLayerProps,
} from './_components';
import { formatProjection, nivoTheme } from './_helpers';

const ResponsiveLine = dynamic(
  () => import('@nivo/line').then((m) => m.ResponsiveLine),
  { ssr: false },
);

type Props = HomepageDocument['education']['graph'] & {
  logo: HomepageDocument['education']['logo'];
  colorScheme: HomepageDocument['education']['colorScheme'];
};

const Assumptions: React.FC<Pick<Props, 'assumptions'>> = ({ assumptions }) => (
  <Text
    color="gray.500"
    textAlign="center"
    fontSize="sm"
    mt={8}
    maxW="5xl"
    mx="auto"
  >
    {assumptions}
  </Text>
);

const EducationGraph: React.FC<Props> = ({
  initial,
  monthly,
  num_years,
  after,
  year,
  years,
  net,
  savings,
  regular,
  raise,
  assumptions,
  colorScheme,
}) => {
  // Project the data
  const raiseProjection = getProjections(
    { initial, monthly: monthly + (monthly * raise.external) / 100 },
    raise.interest,
    num_years,
  );
  const regularProjection = getProjections(
    { initial, monthly: monthly + (monthly * regular.external) / 100 },
    regular.interest,
    num_years,
  );
  const savingsProjection = getProjections(
    { initial, monthly },
    savings.interest,
    num_years,
  );

  // Get the Chakra theme
  const theme = useTheme();

  // Declare the ref for the portal
  const portalRef = React.useRef<HTMLDivElement>(null);

  // Get the colors
  const raiseColor = theme?.colors?.[colorScheme.value]['500'];
  const regularColor = theme?.colors?.[colorScheme.value]['300'];
  const savingsColor = theme?.colors?.gray['400'];

  // Turn the projections into a data array
  const data = [
    {
      id: 'raise',
      name: raise.title,
      color: raiseColor,
      data: formatProjection(raiseProjection, raise.title),
    },
    {
      id: 'regular',
      name: regular.title,
      color: regularColor,
      data: formatProjection(regularProjection, regular.title),
    },
    {
      id: 'savings',
      name: savings,
      color: savingsColor,
      data: formatProjection(savingsProjection, savings.title),
    },
  ];

  // Get the last data points
  const lastRaiseDatum = data[0].data[data[0].data.length - 1];
  const lastRegularDatum = data[1].data[data[1].data.length - 1];
  const lastSavingsDatum = data[2].data[data[2].data.length - 1];

  // Get the max Y value
  const yMax = Math.ceil((lastRaiseDatum.y * 1.1) / 100000) * 100000;

  // Define the ticks for the bottom axis
  const bottomAxisTicks = Array.from(Array(num_years))
    .map((_, i) => (i % 5 === 0 ? i + 4 : null))
    .filter((v) => v);

  // Define the ticks for the left axis
  const leftAxisTicks = Array.from(Array(5))
    .map((_, i) => yMax - (yMax / 5) * i)
    .filter((v) => v)
    .reverse();

  // Define the margins
  const margins = { top: 10, right: 25, bottom: 25, left: 60 };

  return (
    <Box
      my={[8, 12, 16]}
      bg="white"
      p={[6, 8]}
      borderRadius="xl"
      boxShadow={`0px 30px 60px -30px ${
        theme?.colors[colorScheme.value]['200']
      }`}
    >
      <Show below="md">
        <MobileLineDataDisplay
          after={after}
          years={years}
          net={net}
          colorScheme={colorScheme}
          data={[lastRaiseDatum, lastRegularDatum, lastSavingsDatum]}
        />
      </Show>
      <Show above="md">
        <Box h={[36, 72, null, 96]} position="relative">
          <ResponsiveLine
            data={data}
            margin={margins}
            colors={(item) => item.color}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 0,
              max: yMax,
              nice: true,
            }}
            curve="basis"
            axisBottom={{
              tickValues: bottomAxisTicks,
              format: (v) => `${capitalize(year)} ${v + 1}`,
            }}
            axisLeft={{
              tickValues: leftAxisTicks,
              format: (v) => abbreviateUSD(v),
            }}
            enableGridX={true}
            enableGridY={true}
            lineWidth={5}
            enablePoints={false}
            isInteractive
            useMesh
            enableSlices="x"
            sliceTooltip={() => null}
            layers={[
              'grid',
              'markers',
              'axes',
              'areas',
              'crosshair',
              FinalPoints,
              Points as CustomLayer,
              'lines',
              'points',
              'slices',
              'mesh',
              'legends',
              (d) => (
                <Legend
                  after={after}
                  year={year}
                  years={years}
                  net={net}
                  margins={margins}
                  innerRef={portalRef}
                  {...(d as SpecialLayerProps)}
                />
              ),
            ]}
            theme={nivoTheme(theme)}
          />
          <Box ref={portalRef} position="absolute" top={0} left={0} />
        </Box>
      </Show>
      <Assumptions assumptions={assumptions} />
    </Box>
  );
};

export default EducationGraph;
