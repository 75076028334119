import { Button, Flex, Link } from '@chakra-ui/react';
import NextLink from 'next/link';

import { SanityIcon } from '@components/Icon';
import { useLayoutContext } from '@components/Layout';
import type { IconsSharedObject } from '@raise/sanity';

const CtaItem: React.FC<{
  cta: {
    text: string;
    link?: string;
    icon?: IconsSharedObject;
    fn?: string;
  };
  colorScheme: {
    value: string;
  };
  isButton: boolean;
}> = ({ cta, colorScheme, isButton = false }) => {
  const layoutContext = useLayoutContext();

  if (
    !('waitlistDialog' in layoutContext) ||
    !('videoDialog' in layoutContext)
  ) {
    return null;
  }

  const onClick = (type: (typeof cta)['fn']) => {
    if (type === 'waitlist') return layoutContext.waitlistDialog?.onOpen;
    else if (type === 'video') return layoutContext.videoDialog?.onOpen;

    return undefined;
  };

  let elem;

  if (isButton) {
    elem = (
      <Button
        key={cta.text}
        as="a"
        size="lg"
        w={['full', 'auto']}
        cursor="pointer"
        colorScheme={colorScheme.value}
        onClick={onClick(cta.fn)}
        leftIcon={
          cta.icon ? (
            <SanityIcon
              {...cta.icon}
              color="whiteAlpha.700"
              fontSize="xl"
              mr={1}
            />
          ) : undefined
        }
      >
        {cta.text}
      </Button>
    );
  } else {
    elem = (
      <Link
        colorScheme={colorScheme.value}
        fontWeight="semibold"
        onClick={onClick(cta.fn)}
        fontSize="lg"
        cursor="pointer"
        variant="colored"
        w={['full', 'auto']}
      >
        <Flex justifyContent="center" alignItems="center">
          {cta.icon && <SanityIcon {...cta.icon} fontSize="2xl" mr={2} />}
          {cta.text}
        </Flex>
      </Link>
    );
  }

  if (cta.fn === 'link' && cta.link) {
    return (
      <NextLink href={cta.link} passHref legacyBehavior>
        {elem}
      </NextLink>
    );
  }

  return elem;
};

export default CtaItem;
