import {
  type ReactPortal,
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import {
  Box,
  Checkbox,
  Flex,
  Image,
  keyframes,
  Text,
  useBreakpoint,
  useBreakpointValue,
  useTheme,
  useToken,
} from '@chakra-ui/react';
import dynamic from 'next/dynamic';

import { sanityImage } from '@lib/integrations/sanity';
import type { HomepageDocument } from '@raise/sanity';

import { FinalPoints, GraphCard } from './_components';
import { getLast } from './_helpers';

const ResponsiveLine = dynamic(
  () => import('@nivo/line').then((m) => m.ResponsiveLine),
  { ssr: false },
);

type Props = HomepageDocument['savings']['graph'] & {
  logo: HomepageDocument['savings']['logo'];
  colorScheme: HomepageDocument['savings']['colorScheme'];
};

const generateCompetitionNumbers = (
  min: number,
  max: number,
  subdivision: number,
) => {
  const numbers = [];

  for (let i = min; i <= max; i += subdivision) {
    numbers.push(i);
  }

  return [...numbers, ...numbers.reverse().slice(1, numbers.length - 1)];
};

const getValueWrapAroundArray = (array: number[], index: number) => {
  if (index >= array.length) {
    return array[index - array.length];
  }

  return array[index];
};

type LogosProps = {
  points: any[];
  innerRef: RefObject<HTMLDivElement>;
  savings: HomepageDocument['savings']['logo'];
  investment: Props['raise']['investment']['logo'] | boolean;
  education: Props['raise']['education']['logo'] | boolean;
  apy: number;
};

const Logos = ({
  points,
  innerRef,
  savings,
  investment,
  education,
  apy,
}: LogosProps): ReactPortal | null => {
  const raise = getLast(points, 'raise');
  const height = 64;
  const common = { w: 6, h: 6 };

  if (!innerRef.current) return null;

  // Render the below in a portal
  return createPortal(
    <Flex
      direction="column"
      position="absolute"
      left={`${raise.x + 20}px`}
      top={`${raise.y - height / 2}px`}
      h={`${height}px`}
      transition="all 0.2s ease-in-out"
      alignItems="center"
    >
      <Flex alignItems="center" gap={2}>
        <Image src={sanityImage(savings).url()} alt="Savings" {...common} />
        {investment && <Text>+</Text>}
        {investment && (
          <Image
            src={sanityImage(investment).url()}
            alt="Investment"
            {...common}
          />
        )}
        {education && <Text>+</Text>}
        {education && (
          <Image
            src={sanityImage(education).url()}
            alt="Education"
            {...common}
          />
        )}
      </Flex>
      <Text fontSize="2xl" fontWeight="bold" textAlign="center" mt={2}>
        {apy.toFixed(1)}%
      </Text>
    </Flex>,
    innerRef.current,
  );
};

type LegendProps = {
  data: {
    id: string;
    color: string;
    data: {
      x: number;
      y: number;
    }[];
  }[];
  raise: Props['raise'];
  competition: Props['competition'];
};

const Legend: React.FC<LegendProps> = ({ data, raise, competition }) => (
  <GraphCard bottom={[0, 3]} right={[0, 2]} position={['relative', 'absolute']}>
    <Flex direction={['column', 'row']} alignItems="center" gap={[2, 8]}>
      <Flex alignItems="center" gap={2}>
        <Box w={4} h={4} bg={data[0].color} borderRadius="full" />
        <Text flex={1} fontWeight="semibold">
          {raise.name}
        </Text>
      </Flex>
      <Flex alignItems="center" gap={2}>
        <Box w={4} h={4} bg={data[1].color} borderRadius="full" />
        <Text flex={1} fontWeight="semibold">
          {competition.name}
        </Text>
      </Flex>
    </Flex>
  </GraphCard>
);

type CheckboxesProps = {
  services: {
    investment: boolean;
    education: boolean;
  };
  setServices: (services: { investment: boolean; education: boolean }) => void;
  raise: Props['raise'];
};

const Checkboxes: React.FC<CheckboxesProps> = ({
  services,
  setServices,
  raise,
}) => (
  <Flex
    direction={['column', null, 'row']}
    justifyContent="center"
    alignItems="center"
    mt={[32, 8]}
    gap={[4, null, 8]}
  >
    <Checkbox
      size="lg"
      isChecked={services.investment}
      colorScheme={raise.investment.colorScheme.value}
      onChange={(e) =>
        setServices({ ...services, investment: e.target.checked })
      }
    >
      <Flex alignItems="center" gap={2} fontWeight="semibold" fontSize="xl">
        <Text>+</Text>
        <Image
          src={sanityImage(raise.investment.logo).url()}
          alt={raise.investment.text}
          w={6}
          h={6}
        />
        <Text>{raise.investment.text}</Text>
      </Flex>
    </Checkbox>
    <Checkbox
      size="lg"
      isChecked={services.education}
      colorScheme={raise.education.colorScheme.value}
      onChange={(e) =>
        setServices({ ...services, education: e.target.checked })
      }
    >
      <Flex alignItems="center" gap={2} fontWeight="semibold" fontSize="xl">
        <Text>+</Text>
        <Image
          src={sanityImage(raise.education.logo).url()}
          alt={raise.education.text}
          w={6}
          h={6}
        />
        <Text>{raise.education.text}</Text>
      </Flex>
    </Checkbox>
  </Flex>
);

const SavingsGraph: React.FC<Props> = ({
  colorScheme,
  raise,
  competition,
  logo,
}) => {
  // Get the Chakra theme
  const theme = useTheme();

  // Generate the possible numbers for the competition
  const possibleNumbers = useMemo(
    () => generateCompetitionNumbers(competition.min, competition.max, 0.5),
    [competition],
  );

  // Create a unique portal ID
  const portalRef = useRef<HTMLDivElement>(null);

  // Set up the data length, the current index, what services are included, and the data array
  const dataLength = 5;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [services, setServices] = useState({
    education: false,
    investment: false,
  });
  const [data, setData] = useState([
    {
      id: 'raise',
      color: theme?.colors?.[colorScheme.value]?.['500'],
      data: Array.from({ length: dataLength }, (_, index) => ({
        x: index,
        y: raise.base,
      })),
    },
    {
      id: 'competition',
      color: theme?.colors?.gray?.['400'],
      data: possibleNumbers
        .slice(currentIndex, dataLength)
        .map((number, index) => ({
          x: index,
          y: number,
        })),
    },
  ]);

  // A function to get the Raise APY
  const raiseAPY = useMemo(
    () =>
      raise.base +
      (services.education ? raise.education.value : 0) +
      (services.investment ? raise.investment.value : 0),
    [raise, services],
  );

  // A function that changes the data array
  const change = useCallback(() => {
    // Clone the data array
    const newData = [...data];

    // ----- RAISE ----- //
    // Get the raise data
    const raiseData = newData[0].data;

    // Add the education and investment APY boost to the raise data
    raiseData.push({
      x: raiseData.length,
      y: raiseAPY,
    });

    // Remove the first raise data point
    raiseData.shift();

    // Update the x values of the raise data
    raiseData.forEach((point, index) => {
      point.x = index;
    });

    // Update the data array with the new raiseData
    newData[0].data = raiseData;

    // ----- COMPETITION ----- //
    // Go up and down the scale of possible numbers, and keep track of the index using currentIndex
    if (currentIndex === possibleNumbers.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }

    // Generate the competition data based on the current index
    const competitionData = Array.from({ length: dataLength }, (_, index) =>
      getValueWrapAroundArray(possibleNumbers, currentIndex + index),
    ).map((number, index) => ({
      x: index,
      y: number,
    }));

    // Update the data array with the new competitionData
    newData[1].data = competitionData;

    // Return the new data array
    return newData;
  }, [data, currentIndex, raiseAPY, possibleNumbers]);

  // On mount, set up the data change interval
  useEffect(() => {
    // Update the data every 250ms
    const intervalId = setInterval(() => {
      setData(change());
    }, 250);

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, [change]);

  // A function to get the keyframe animation speed based on what services are enabled
  const keyframeSpeed = useMemo(() => {
    if (services.education && services.investment) {
      return 0.333;
    } else if (services.education || services.investment) {
      return 0.666;
    } else {
      return 1;
    }
  }, [services]);

  const height = useBreakpointValue([36, 72, null, 96]);
  const currentHeight = useToken('sizes', height!) as unknown as string;
  const currentPixelHeight =
    parseInt(currentHeight.slice(0, currentHeight.indexOf('rem'))) * 16;

  const breakpoint = useBreakpoint();

  // Set up the grid size, the keyframe animation, and the line styles
  const gridSize = currentPixelHeight / (breakpoint === 'base' ? 5 : 10);
  const keyframeAnimation = useMemo(
    () => keyframes`
    from { background-position: 0px 0px, ${gridSize}px 0px; }
    to { background-position: 0px 0px, -${gridSize}px 0px; }
  `,
    [gridSize],
  );
  const line = {
    color: theme?.colors?.gray['200'],
    thickness: '1px',
  };

  return (
    <Box
      my={[8, 12, 16]}
      bg="white"
      p={[6, 8]}
      borderRadius="xl"
      boxShadow={`0px 30px 60px -30px ${
        theme?.colors[colorScheme.value]['200']
      }`}
    >
      <Box
        w="full"
        h={`${currentPixelHeight + 1}px`}
        position="relative"
        backgroundImage={`
          -webkit-linear-gradient(top, ${line.color} ${line.thickness}, transparent ${line.thickness}),
          -webkit-linear-gradient(left, ${line.color} ${line.thickness}, transparent ${line.thickness})
        `
          .split('\n')
          .join('')}
        backgroundSize={`${gridSize}px ${gridSize}px, ${gridSize}px ${gridSize}px`}
        animation={`${keyframeAnimation} ${keyframeSpeed}s infinite linear`}
      >
        <ResponsiveLine
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          data={data}
          colors={(item) => item.color}
          xScale={{ type: 'linear', min: 0, max: dataLength * 2 - 2 }}
          yScale={{
            type: 'linear',
            min: 0,
            max: Math.ceil(competition.max) + 1,
          }}
          axisTop={null}
          axisBottom={null}
          axisLeft={null}
          axisRight={null}
          lineWidth={5}
          enablePoints={false}
          enableGridX={false}
          enableGridY={false}
          curve="basis"
          isInteractive={false}
          enableSlices={false}
          useMesh={true}
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            FinalPoints,
            (props) => (
              <Logos
                {...props}
                innerRef={portalRef}
                savings={logo}
                investment={services.investment && raise.investment.logo}
                education={services.education && raise.education.logo}
                apy={raiseAPY}
              />
            ),
            'lines',
            'points',
            'slices',
            'mesh',
            'legends',
          ]}
        />
        <Box
          ref={portalRef}
          position="absolute"
          top={0}
          left={0}
          w="full"
          h="full"
        />
        <Legend data={data} raise={raise} competition={competition} />
      </Box>
      <Checkboxes services={services} setServices={setServices} raise={raise} />
    </Box>
  );
};

export default SavingsGraph;
