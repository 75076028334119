import React from 'react';
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { PortableText } from '@portabletext/react';

import { SanityIcon } from '@components/Icon';
import PageContainer from '@components/PageContainer';
import Underline from '@components/Underline';
import { sanityImage } from '@lib/integrations/sanity';
import type { HomepageDocument } from '@raise/sanity';

import EducationGraph from './EducationGraph';
import InvestmentGraph from './InvestmentGraph';
import SavingsGraph from './SavingsGraph';

import CtaItem from '../CTAItem';

type Props = (
  | HomepageDocument['investment']
  | HomepageDocument['savings']
  | HomepageDocument['education']
) & {
  type: 'investment' | 'savings' | 'education';
};

const sectionPadding = [16, null, 24];
const offsetPadding = [48];

const Product: React.FC<Props> = ({
  type,
  tagline,
  description,
  logo,
  colorScheme,
  features,
  ctas,
  graph,
}) => (
  <Box>
    <Box
      bgGradient={`linear-gradient(to-b, ${colorScheme.value}.100, transparent)`}
    >
      <PageContainer pt={sectionPadding} pb={offsetPadding}>
        <Flex alignItems="center" gap={8} mb={8}>
          <Image src={sanityImage(logo).url()} alt={type} h={20} />
          <Heading as="h2" size="2xl" maxW="4xl" lineHeight="normal">
            {tagline}
          </Heading>
        </Flex>
        <PortableText
          value={description}
          components={{
            block: {
              normal: ({ children }) => (
                <Text
                  lineHeight="tall"
                  fontSize="xl"
                  maxW="4xl"
                  color="gray.700"
                >
                  {children}
                </Text>
              ),
            },
            marks: {
              strong: ({ children }) => (
                <Text as="b" fontWeight="semibold">
                  {children}
                </Text>
              ),
              em: ({ children }) => (
                <Text as="i" fontStyle="italic">
                  {children}
                </Text>
              ),
              underline: ({ children }) => (
                <Text as="span" textDecoration="underline">
                  {children}
                </Text>
              ),
              highlight: ({ text, value }) => (
                <Underline height="3px" color={value.value}>
                  {text}
                </Underline>
              ),
            },
          }}
        />
        <Flex
          direction={['column', null, 'row']}
          mt={8}
          alignItems="center"
          gap={6}
        >
          {ctas.map((cta, i) => (
            <CtaItem
              key={cta.text}
              cta={cta}
              colorScheme={colorScheme}
              isButton={i === 0}
            />
          ))}
        </Flex>
      </PageContainer>
    </Box>
    <Box mt={offsetPadding.map((i) => (i ? i * -1 : null))}>
      <PageContainer pb={sectionPadding}>
        <Box mt={12}>
          {type === 'investment' && (
            <InvestmentGraph
              {...(graph as HomepageDocument['investment']['graph'])}
              logo={logo}
              colorScheme={colorScheme}
            />
          )}
          {type === 'savings' && (
            <SavingsGraph
              {...(graph as HomepageDocument['savings']['graph'])}
              logo={logo}
              colorScheme={colorScheme}
            />
          )}
          {type === 'education' && (
            <EducationGraph
              {...(graph as HomepageDocument['education']['graph'])}
              logo={logo}
              colorScheme={colorScheme}
            />
          )}
        </Box>
        <Flex
          mt={12}
          direction={['column', null, 'row']}
          justifyContent="space-evenly"
          alignItems="flex-start"
          gap={8}
        >
          {features.map((feature) => (
            <Box key={feature.title} flex={1}>
              <Flex alignItems="center" mb={4}>
                <SanityIcon
                  {...feature.icon}
                  color={`${colorScheme.value}.500`}
                  fontSize="3xl"
                  mr={4}
                />
                <Text fontSize="lg" fontWeight="semibold">
                  {feature.title}
                </Text>
              </Flex>
              <Text fontSize="lg" color="blackAlpha.800">
                {feature.description}
              </Text>
            </Box>
          ))}
        </Flex>
      </PageContainer>
    </Box>
  </Box>
);

export default Product;
